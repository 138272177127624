import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { typography } from "styled-system"

// Import Global Components
import LeftColumn from "~components/leftColumn"

const Title = styled.h1`
  ${typography}
  box-sizing: border-box;
  font-size: 2.5rem;
  margin-bottom: -0.4rem;
  font-weight: normal;
  @media (max-width: 900px) {
    margin-top: 1.5rem;
  }
`

const Paragraph = styled.p`
  margin-bottom: 1rem;
`

const Container = styled.div`
  margin: 1rem 0;
  padding: 1rem 0;
  border-top: 2px black solid;
  border-bottom: 2px black solid;
`

const Description = styled.p`
  white-space: break-spaces;
`

export default ({
  data: {
    sanityWebsite: { contacts },
  },
}) => {
  return (
    <LeftColumn style={{ padding: "0.75rem" }}>
      <Title fontFamily={"moonbase"}>Contact</Title>
      <Container>
        <Paragraph>
          <b>General Inquiries:</b>{" "}
          <a
            href={`mailto:${contacts[0].emailAddress}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {contacts[0].emailAddress}
          </a>
        </Paragraph>
        <Paragraph>{contacts[0].address}</Paragraph>
      </Container>
      <Description>{contacts[0].description}</Description>
    </LeftColumn>
  )
}

export const query = graphql`
  query ContactPageQuery {
    sanityWebsite(id: { eq: "c69612d2-015f-5829-8e1a-e6dc9b7a3927" }) {
      contacts {
        _key
        emailAddress
        address
        description
      }
    }
  }
`
